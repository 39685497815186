import { createSvgIcon } from '@mui/material'

export const AirTemperature = createSvgIcon(
  <svg viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="49" cy="49" r="48.75" stroke="#8DC63F" strokeWidth="0.5" />
    <path
      d="M71.0006 47.8043C71.0191 47.629 71.0747 47.463 71.0747 47.2877C71.0747 44.0312 68.4175 41.3836 65.1494 41.3836C63.9828 41.3836 62.9089 41.7342 61.9923 42.3061C60.3628 38.7268 56.7613 36.2175 52.5581 36.2175C46.8364 36.2175 42.1888 40.8393 42.1888 46.5497C42.1888 46.8265 42.2536 47.0848 42.2721 47.3523C37.68 47.6936 34.0415 51.4759 34.0415 56.1439C34.0415 61.0332 38.0225 65 42.9294 65H68.112C73.0189 65 77 61.0332 77 56.1439C77 52.2601 74.4817 49.0036 71.0006 47.8043ZM30.7085 38.4131C30.7085 42.177 33.4212 45.3135 36.9949 46.0054C37.7355 45.5718 38.504 45.1936 39.328 44.9261C39.8279 40.8393 42.198 37.3153 45.5681 35.2397C44.346 32.5551 41.6333 30.6732 38.4854 30.6732C34.1989 30.6732 30.7085 34.1419 30.7085 38.4131Z"
      fill="#8DC63F"
    />
    <path
      d="M37.6947 21.6933C37.6947 21.3104 38.0062 21 38.3903 21C38.7745 21 39.086 21.3104 39.086 21.6933V27.2393C39.086 27.6222 38.7745 27.9326 38.3903 27.9326C38.0062 27.9326 37.6947 27.6222 37.6947 27.2393V21.6933Z"
      fill="#8DC63F"
    />
    <path
      d="M27.2605 37.6381C27.6447 37.6381 27.9561 37.9485 27.9561 38.3314C27.9561 38.7142 27.6447 39.0246 27.2605 39.0246H21.6956C21.3114 39.0246 21 38.7142 21 38.3314C21 37.9485 21.3114 37.6381 21.6956 37.6381H27.2605Z"
      fill="#8DC63F"
    />
    <path
      d="M49.7033 26.0763C49.9749 25.8055 50.4153 25.8055 50.687 26.0763C50.9587 26.347 50.9587 26.786 50.687 27.0567L46.752 30.9783C46.4804 31.2491 46.0399 31.2491 45.7683 30.9783C45.4966 30.7076 45.4966 30.2687 45.7683 29.9979L49.7033 26.0763Z"
      fill="#8DC63F"
    />
    <path
      d="M30.0285 45.6846C30.3001 45.4138 30.7406 45.4138 31.0122 45.6846C31.2839 45.9553 31.2839 46.3942 31.0122 46.665L27.0773 50.5866C26.8056 50.8574 26.3652 50.8574 26.0935 50.5866C25.8219 50.3159 25.8219 49.8769 26.0935 49.6062L30.0285 45.6846Z"
      fill="#8DC63F"
    />
    <path
      d="M31.0122 29.9979C31.2839 30.2687 31.2839 30.7076 31.0122 30.9783C30.7406 31.2491 30.3001 31.2491 30.0285 30.9783L26.0935 27.0567C25.8219 26.786 25.8219 26.347 26.0935 26.0763C26.3652 25.8056 26.8056 25.8056 27.0773 26.0763L31.0122 29.9979Z"
      fill="#8DC63F"
    />
  </svg>,
  'AirTemperature',
)
