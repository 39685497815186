import { ApexOptions } from 'apexcharts'
import React from 'react'

const Chart = React.lazy(() => import('react-apexcharts'))

export default Chart

export const radialBarBaseConfig: ApexOptions = {
  plotOptions: {
    radialBar: {
      hollow: {
        size: '82%',
      },

      dataLabels: {
        show: true,
        name: {
          offsetY: 20,
          show: true,
          color: '#000000DE',
          fontSize: '12px',
          fontWeight: 300,
        },
        value: {
          color: '#00AEFF',
          fontSize: '2em',
          show: true,
          offsetY: -20,
        },
        total: {
          show: false,
          label: 'Total',
          color: '#000000DE',
          fontSize: '16px',
        },
      },
    },
  },
  fill: {
    type: 'solid',
    colors: ['#00AEFF'],
  },
  stroke: {
    lineCap: 'round',
  },
}
