import { createSvgIcon } from '@mui/material'

const LocationOn3d = createSvgIcon(
  <svg viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.5705 13.5036L36.2357 26.6538C36.2215 28.7924 32.7922 31.0099 28.5792 31.5857L27.9835 31.667L28.1821 22.9616C26.5631 22.6268 24.7943 22.3863 22.9151 22.2425L23.4281 21.2589C25.119 21.4029 26.7213 21.6247 28.2058 21.9234L28.3082 17.4374L28.7068 17.3542C32.9271 16.4744 35.5494 14.9687 35.5522 13.4246L35.5544 13.4247C35.4752 11.888 32.2174 9.90318 25.1872 9.03382C24.8949 8.61958 24.5625 8.23643 24.1952 7.88931C24.3829 7.90903 24.5726 7.92897 24.765 7.95063C32.0535 8.7701 36.5805 10.9115 36.5805 13.5038L36.5705 13.5036ZM2.4476 13.5231C2.45633 15.0653 5.0775 16.5686 9.29303 17.4474L9.69162 17.5306L10.0163 31.7602L9.42061 31.6789C5.20757 31.1031 1.77829 28.8856 1.76414 26.747L1.42773 13.5338L2.26241 13.5207L1.42773 13.5101C1.42773 10.2831 8.05225 8.46212 13.8105 7.89222C13.445 8.23806 13.1141 8.61993 12.8232 9.03181C6.0129 9.85986 2.45648 11.8465 2.4538 13.4803V13.5232L2.4476 13.5231ZM9.79786 21.8747C11.2847 21.5857 12.8829 21.3727 14.5699 21.2376L15.085 22.2258C13.2107 22.3594 11.4443 22.5897 9.82155 22.9134L9.79786 21.8747Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0035 9.69764C17.085 9.69764 15.5295 11.2577 15.5295 13.1762C15.5295 15.0927 17.085 16.6482 19.0035 16.6482C20.924 16.6482 22.4794 15.0927 22.4794 13.1762C22.4794 11.2577 20.924 9.69764 19.0035 9.69764ZM19.0035 27.4645L13.1424 16.2222C12.7091 15.346 12.4668 14.3604 12.4668 13.3212C12.4668 9.71029 15.3934 6.78585 19.0035 6.78585C22.6156 6.78585 25.5428 9.71029 25.5428 13.3212C25.5428 14.3986 25.2811 15.4129 24.8239 16.3064L19.0035 27.4645Z"
      fill="white"
    />
  </svg>,
  'LocationOn3d',
)

export default LocationOn3d
