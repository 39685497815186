import { Box } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { AirCo2 } from '../../../icons/air-co2'
import { AirPressure } from '../../../icons/air-pressure'
import { AirTemperature } from '../../../icons/air-temperature'
import { HumidityRate } from '../../../icons/humidity-rate'
import { ScmDataDisplayBaseProps } from '../../../smart-city-monitor/types/base-props'
import { SensorGroupDataEnvironment } from '../../../smart-city-monitor/types/sensors'
import LastUpdateInfo from '../last-update-info'
import QuickStatsHorizontal from '../quick-stats-horizontal'
import ScmCard from '../scm-card/scm-card'

import EnvironmentFlipContent from './environment-flip-content'

function EnvironmentCard({
  data,
  isError,
  isConnected,
}: ScmDataDisplayBaseProps) {
  const environmentData = data as SensorGroupDataEnvironment
  const spotColor = '#8DC63F'

  let airPressureHpa = environmentData?.aggregation.atmoPressure
  if (typeof airPressureHpa === 'number' && airPressureHpa > 0) {
    airPressureHpa = Math.trunc(airPressureHpa / 100)
  }

  return (
    <ScmCard
      isConnected={isConnected}
      isError={isError}
      data={environmentData}
      custom={{
        title: 'Umweltdaten',
        color: spotColor,
        loadingContentHeight: '346px',
        additionalCss: {
          backgroundImage: 'url("/background-images/environment.svg")',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom',
        },
      }}
      flipContent={<EnvironmentFlipContent />}
    >
      <Box
        sx={{
          textAlign: 'center',
          p: [0, 4],
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid
            xs={12}
            sm={12}
            md={6}
            sx={{
              textAlign: ['center', 'center', 'right'],
            }}
          >
            <QuickStatsHorizontal
              value={
                environmentData?.aggregation.airTemperature
                  ? `${environmentData.aggregation.airTemperature.toLocaleString(
                      'de-DE',
                    )}°C`
                  : '?'
              }
              title="Aktuelle Lufttemperatur"
              icon={<AirTemperature sx={{ height: 80, width: 80 }} />}
              width={220}
              color={spotColor}
            />
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={6}
            sx={{
              textAlign: ['center', 'center', 'left'],
            }}
          >
            <QuickStatsHorizontal
              value={
                environmentData?.aggregation.airHumidity
                  ? `${environmentData.aggregation.airHumidity.toLocaleString(
                      'de-DE',
                    )}%`
                  : '?'
              }
              title="Aktuelle Luftfeuchtigkeit"
              icon={<HumidityRate sx={{ height: 80, width: 80 }} />}
              width={220}
              color={spotColor}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid
            xs={12}
            sm={12}
            md={6}
            sx={{
              textAlign: ['center', 'center', 'right'],
            }}
          >
            <QuickStatsHorizontal
              value={
                typeof airPressureHpa === 'number'
                  ? `${airPressureHpa} hPa`
                  : '?'
              }
              title="Aktueller Luftdruck"
              icon={<AirPressure sx={{ height: 80, width: 80 }} />}
              width={220}
              color={spotColor}
            />
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={6}
            sx={{
              textAlign: ['center', 'center', 'left'],
            }}
          >
            <QuickStatsHorizontal
              value={
                environmentData?.aggregation.co2Ppm
                  ? `${environmentData.aggregation.co2Ppm} ppm`
                  : '?'
              }
              title={
                <span>
                  CO<sub>2</sub> in der Luft
                </span>
              }
              icon={<AirCo2 sx={{ height: 80, width: 80 }} />}
              width={220}
              color={spotColor}
            />
          </Grid>
        </Grid>
      </Box>
      <LastUpdateInfo date={data?.updatedAt} absouluteBottonPosition="-10px" />
    </ScmCard>
  )
}

export default EnvironmentCard
