import { createSvgIcon } from '@mui/material'

export const HumidityRate = createSvgIcon(
  <svg viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="49" cy="49" r="48.75" stroke="#8DC63F" strokeWidth="0.5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.1622 38.8178L70.8722 41.5804L73.4968 42.4902C77.5828 43.9066 80.5 47.7351 80.5 52.2705C80.5 58.019 75.8494 62.6685 70.1379 62.6685H30.8621C25.1506 62.6685 20.5 58.019 20.5 52.2705C20.5 46.8113 24.7279 42.3657 30.0979 41.9641L33.5674 41.7046L33.3286 38.2337C33.3146 38.0306 33.2934 37.8495 33.275 37.7085C33.2635 37.6207 33.2478 37.5097 33.2371 37.4339L33.237 37.4339L33.2278 37.3685C33.2125 37.2579 33.208 37.2105 33.2069 37.1988C33.2153 30.1711 38.9003 24.5 45.8793 24.5C50.989 24.5 55.3999 27.5668 57.4045 31.9976L59.0578 35.6518L62.4545 33.519C63.3838 32.9355 64.421 32.6072 65.5172 32.6072C68.6196 32.6072 71.1712 35.1002 71.2564 38.2117L71.256 38.2137C71.249 38.2501 71.2392 38.301 71.2291 38.3567C71.2091 38.4676 71.182 38.6291 71.1622 38.8178ZM17 52.2705C17 44.9451 22.6748 39.0095 29.8368 38.4739L29.8335 38.4293C29.8207 38.2708 29.7985 38.1142 29.7762 37.957C29.7416 37.7136 29.7069 37.4689 29.7069 37.2144C29.7069 37.0772 29.7086 36.9404 29.712 36.8041C29.9292 28.0327 37.0922 21 45.8793 21C51.1837 21 55.8737 23.5781 58.817 27.5379C59.5112 28.4719 60.1083 29.4828 60.5933 30.5549C61.3318 30.0912 62.1357 29.7203 62.9912 29.47C63.7919 29.2357 64.6377 29.1072 65.5172 29.1072C70.6144 29.1072 74.7586 33.2621 74.7586 38.3725C74.7586 38.5365 74.7278 38.6954 74.6968 38.8553C74.6758 38.9635 74.6548 39.0722 74.6431 39.1832C80.0724 41.0653 84 46.1757 84 52.2705C84 59.9434 77.791 66.1685 70.1379 66.1685H30.8621C23.209 66.1685 17 59.9434 17 52.2705ZM61.4157 44.8582C61.4157 51.3825 49.7472 60.146 49.7472 60.146C49.7472 60.146 38.0786 51.3825 38.0786 44.8582C38.0786 38.3339 43.3028 33.0449 49.7472 33.0449C56.1915 33.0449 61.4157 38.3339 61.4157 44.8582ZM58.2324 43.8604C58.2324 45.8679 55.9445 48.5644 55.9445 48.5644C55.9445 48.5644 53.6566 45.8679 53.6566 43.8604C53.6566 41.8529 54.6809 40.2256 55.9445 40.2256C57.2081 40.2256 58.2324 41.8529 58.2324 43.8604Z"
      fill="#8DC63F"
    />
  </svg>,
  'HumidityRade',
)
