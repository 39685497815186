import { Box } from '@mui/material'

const StreetLightsFlipContent = () => {
  return (
    <Box
      sx={{
        height: ['auto', 'auto', '258px'],
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <p>
        24 neue energiesparende LED-Leuchten sorgen für eine sichere und
        umweltfreundliche Straßenbeleuchtung. Alle Leuchten sind miteinander
        vernetzt, digital mit dem Betrieb verbunden und fernsteuerbar.
      </p>

      <p>
        Zusätzliche Besonderheit dieser smarten Beleuchtung:
        <br />
        Je nach Verkehrsaufkommen auf der Friedrich-Wilhelm-Str. wird das Licht
        bedarfsgerecht in zugelassenen Grenzen angepasst.
      </p>

      <p>So wird zusätzlich Energie gespart und die Umwelt geschont.</p>
    </Box>
  )
}

export default StreetLightsFlipContent
