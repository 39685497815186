import { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import { Box, CardContent, Fade } from '@mui/material'

interface ScmCardContentProps {
  showFlipContent: boolean
  mainContent: React.ReactNode
  flipContent?: EmotionJSX.Element
}

const ScmCardContent = (props: ScmCardContentProps) => {
  const { mainContent, flipContent = undefined, showFlipContent } = props

  return (
    <CardContent
      sx={{
        pt: 0,
        height: '100%',
        position: 'relative',
      }}
    >
      {showFlipContent === false ? (
        <Box>{mainContent}</Box>
      ) : (
        <Fade in timeout={500}>
          <Box>
            {typeof flipContent !== 'undefined' && <Box>{flipContent}</Box>}
          </Box>
        </Fade>
      )}
    </CardContent>
  )
}

ScmCardContent.defaultProps = {
  flipContent: undefined,
}

export default ScmCardContent
