export enum SensorDataType {
  Parking = 'parking',
  Environment = 'environment',
  StreetLights = 'streetLights',
  WifiHotspots = 'wifiHotspots',
}

export interface SensorGroupData<T> {
  id: string
  type: SensorDataType
  aggregation: {
    totalCount: number
    [key: string]: any
  }
  updatedAt: Date | null
  sensors: T[]
}

export interface SensorGroupDataStreetLights
  extends SensorGroupData<SensorDataStreetLight> {
  type: SensorDataType.StreetLights
  aggregation: {
    totalCount: number
    onCount: number
    offCount: number
  }
}

export interface SensorGroupDataParking
  extends SensorGroupData<SensorDataParking> {
  type: SensorDataType.Parking
  aggregation: {
    totalCount: number
    occupiedCount: number
    freeCount: number
  }
}

export interface SensorGroupDataWifiHotspots
  extends SensorGroupData<SensorDataWifiHotspot> {
  type: SensorDataType.WifiHotspots
  aggregation: {
    totalCount: number
    associatedClientsCount: number
    authenticatedClientsCount: number
  }
}

export interface SensorGroupDataEnvironment
  extends SensorGroupData<SensorDataEnvironment> {
  type: SensorDataType.Environment
  aggregation: {
    totalCount: number
    airTemperature?: number
    airHumidity?: number
    co2Ppm?: number
    atmoPressure?: number
  }
}

export interface SensorData {
  id: string
  name: string
  location: GeoLocation
  createdAt: Date
}

export interface GeoLocation {
  latitude: number
  longitude: number
}

export interface SensorDataEnvironment extends SensorData {
  airTemperature?: number
  airHumidity?: number
  co2Ppm?: number
  atmoPressure?: number
}

export interface SensorDataParking extends SensorData {}

export interface SensorDataWifiHotspot extends SensorData {}

export interface SensorDataStreetLight extends SensorData {
  status: 'on|off'
}
