import _ from 'lodash'
import { ScmDataChartProps } from '../../../smart-city-monitor/types/base-props'
import { SensorGroupDataStreetLights } from '../../../smart-city-monitor/types/sensors'
import Chart, { radialBarBaseConfig } from './chart'

const config = _.merge(radialBarBaseConfig, {
  labels: ['Eingeschaltet'],
})

const StreetLightsChart = ({ data }: ScmDataChartProps) => {
  const streetLightData = data as SensorGroupDataStreetLights

  return (
    <Chart
      series={[
        Math.round(
          ((streetLightData?.aggregation.onCount ?? 0) /
            (streetLightData?.aggregation.totalCount ?? 1)) *
            100,
        ),
      ]}
      type="radialBar"
      width="100%"
      height="100%"
      options={config}
    />
  )
}
export default StreetLightsChart
