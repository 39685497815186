/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Box, Link, useTheme } from '@mui/material'
import { ReactElement } from 'react'
import LocationOn3d from '../../icons/location-on-3d'

interface LocationLabelProps {
  location: string
}

function LocationLabel({ location }: LocationLabelProps): ReactElement {
  const labelStyles = css({
    // fontSize: '1rem',
  })

  return (
    <Box component="div" css={labelStyles}>
      <Box component="div" css={{ fontWeight: 'bold', fontSize: '0.83rem' }}>
        Digitale Tour
      </Box>
      <Box component="div" css={{ fontSize: '0.83rem' }}>
        für {location}
      </Box>
    </Box>
  )
}

interface DigitalTourProps {
  location: string
}
function DigitalTour({ location }: DigitalTourProps): ReactElement {
  const theme = useTheme()

  const rootStyles = css({
    textDecoration: 'none',
    width: '100%',
  })

  const divStyles = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    padding: '10px 30px',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }

  const iconStyles = css({
    marginLeft: -6,
    marginRight: 11,
    fontSize: theme.typography.pxToRem(28),
  })
  return (
    <Link
      css={rootStyles}
      href="https://digiscan.duisburgcity.com/public/?site=3879643238509676&vlon=6.28&vlat=0.00&fov=100.0&image=2609321445873423"
      target="_blank"
      rel="noreferrer"
    >
      <Box component="div" css={divStyles}>
        <LocationOn3d css={css(iconStyles)} />
        <LocationLabel location={location} />
      </Box>
    </Link>
  )
}

export default DigitalTour
