import { ThemeOptions } from '@mui/material'

const baseThemeOptions: ThemeOptions = {
  spacing: 10,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1240,
      xl: 1440,
    },
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        circular: {
          borderStyle: 'solid',
          borderWidth: '0.5px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeSmall: {
          fontSize: '0.88rem',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'button',
        },
      },
      styleOverrides: {
        root: {
          paddingLeft: 0,
        },
        title: {
          fontSize: '1.24rem',
          textTransform: 'uppercase',
          letterSpacing: '3.5px',
        },
        avatar: {
          width: 20,
          height: 20,
          marginLeft: -10,
          zIndex: 10000,
        },
      },
    },
  },
  typography: {
    fontFamily: 'Swiss721',
    fontSize: 17,
    button: {
      textTransform: 'none',
      fontWeight: 600,
      fontSize: '0.95rem',
    },
    body1: {
      fontSize: '1.0rem',
      fontWeight: 300,
      lineHeight: 1.6,
      letterSpacing: 0.5,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 300,
      lineHeight: 1.57,
    },
    caption: {
      fontSize: '0.76rem',
      fontWeight: 300,
      lineHeight: 1.57,
    },

    h1: {
      fontWeight: 800,
      fontSize: '2.12rem',
      lineHeight: 1.2,
    },
    h2: {
      fontWeight: 800,
      fontSize: '2.12rem',
      lineHeight: 1.2,
    },
  },
}

export default baseThemeOptions
