/* eslint-disable react/no-unknown-property */
/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import React, { ReactElement } from 'react'
import { Container, Divider } from '@mui/material'
import Partner from './partner'
import BottomLinks from './bottom-links'

function Footer(): ReactElement {
  const footerStyles = css({
    marginTop: 80,
    marginBottom: 100,
  })

  return (
    <div css={footerStyles}>
      <Container>
        <Partner />
      </Container>
      <Divider sx={{ borderWidth: '1px' }} light />
      <Container>
        <BottomLinks />
      </Container>
    </div>
  )
}

export default Footer
