import { Box, Container, useTheme } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import ScmDataContainer from '../smart-city-monitor/components/scm-data-container'
import { SensorDataType } from '../smart-city-monitor/types/sensors'
import Introduction from './introduction'
import LocationInformation from './location/location-information'
import EnvironmentCard from './scm-cards/environment/environment-card'
import ParkingCard from './scm-cards/parking/parking-card'
import StreetLightsCard from './scm-cards/street-lights/street-lights-card'
import WifiHotspotsCard from './scm-cards/wifi-hotspots/wifi-hotspots-card'

export function SmartCityInformation() {
  const theme = useTheme()

  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: 10,
        flexGrow: 1,
        [theme.breakpoints.down('md')]: {
          mt: 7,
        },
      }}
    >
      <Grid container justifyContent="center">
        <Grid sx={{ maxWidth: 820 }}>
          <Introduction />
          <LocationInformation location="Friedrich-Wilhelm-Straße" />
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid md={6} xs={12}>
          <ScmDataContainer
            component={ParkingCard}
            group={SensorDataType.Parking}
          />
        </Grid>
        <Grid md={6} xs={12}>
          <ScmDataContainer
            component={WifiHotspotsCard}
            group={SensorDataType.WifiHotspots}
          />
          <Box sx={{ mt: 2 }} />
          <ScmDataContainer
            component={StreetLightsCard}
            group={SensorDataType.StreetLights}
          />
        </Grid>
        <Grid md={12} xs={12}>
          <ScmDataContainer
            component={EnvironmentCard}
            group={SensorDataType.Environment}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
