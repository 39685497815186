import { Box, useMediaQuery, useTheme } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { LightOff } from '../../../icons/light-off'
import { LightOn } from '../../../icons/light-on'
import { ScmDataDisplayBaseProps } from '../../../smart-city-monitor/types/base-props'
import { SensorGroupDataStreetLights } from '../../../smart-city-monitor/types/sensors'
import LastUpdateInfo from '../last-update-info'
import QuickStatsVertical from '../quick-stats-vertical'
import ScmCard from '../scm-card/scm-card'
import StreetLightsChart from './street-lights-chart'
import StreetLightsFlipContent from './street-lights-flip-content'

function StreetLightsCard({
  data,
  isConnected,
  isError,
}: ScmDataDisplayBaseProps) {
  const theme = useTheme()
  const screenInXsMode = useMediaQuery(theme.breakpoints.down('sm'))
  const streetLightData = data as SensorGroupDataStreetLights
  const spotColor = '#F7941D'

  return (
    <ScmCard
      data={streetLightData}
      isError={isError}
      isConnected={isConnected}
      custom={{
        title: screenInXsMode ? 'Beleuchtung' : 'Straßenbeleuchtung',
        color: spotColor,
        loadingContentHeight: '274px',
        additionalCss: {
          backgroundImage: 'url("/background-images/street-lights.svg")',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom',
        },
      }}
      flipContent={<StreetLightsFlipContent />}
    >
      <Box
        sx={{
          textAlign: 'center',
          p: 4,
        }}
      >
        <Grid container sx={{ p: 0 }} spacing={2}>
          <Grid xs={12} sm={4} md={4}>
            <QuickStatsVertical
              value={streetLightData?.aggregation.totalCount ?? 'X'}
              title="Digital erfasste Leuchten"
              icon={<LightOff sx={{ height: 80, width: 80 }} />}
            />
          </Grid>
          <Grid xs={12} sm={4} md={4}>
            <QuickStatsVertical
              value={streetLightData?.aggregation.onCount ?? 'X'}
              title="Angeschaltete Leuchten"
              icon={<LightOn sx={{ height: 80, width: 80 }} />}
            />
          </Grid>

          <Grid
            xs={12}
            sm={4}
            md={4}
            sx={{
              borderLeft: ['none', `2px solid ${spotColor}`],
              pl: [0, 0, 0, 0, 2],
              textAlign: 'center',
            }}
            color={spotColor}
          >
            <Box sx={{ maxHeight: '135px', mt: [0, 0, -1.5] }}>
              <StreetLightsChart data={data} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <LastUpdateInfo
        date={streetLightData?.updatedAt}
        absouluteBottonPosition="-10px"
      />
    </ScmCard>
  )
}

export default StreetLightsCard
