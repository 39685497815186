import _ from 'lodash'
import { ScmDataChartProps } from '../../../smart-city-monitor/types/base-props'
import { SensorGroupDataParking } from '../../../smart-city-monitor/types/sensors'
import Chart, { radialBarBaseConfig } from './chart'

const config = _.merge(radialBarBaseConfig, {
  labels: ['der Parkplätze sind belegt'],
})

const ParkingChart = ({ data }: ScmDataChartProps) => {
  const parkingData = data as SensorGroupDataParking

  return (
    <Chart
      series={[
        Math.round(
          ((parkingData?.aggregation.occupiedCount ?? 0) /
            (parkingData?.aggregation.totalCount ?? 1)) *
            100,
        ),
      ]}
      type="radialBar"
      width="100%"
      height="100%"
      options={config}
    />
  )
}
export default ParkingChart
