import { FC, ReactElement } from 'react'
import { Box, Typography } from '@mui/material'

interface QuickstatsHorizontalProps {
  value: string | number
  title: string
  icon: ReactElement
}

// eslint-disable-next-line react/function-component-definition
const QuickStatsVertical: FC<QuickstatsHorizontalProps> = ({
  value,
  title,
  icon,
}) => {
  return (
    <Box
      component="div"
      sx={{
        textAlign: 'center',
      }}
    >
      <Box component="div" sx={{ width: '100%' }}>
        {icon}
      </Box>
      <Box component={Typography} variant="h2">
        {value}
      </Box>
      <Box component="div">
        <Typography variant="body2">{title}</Typography>
      </Box>
    </Box>
  )
}

export default QuickStatsVertical
