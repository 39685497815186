import { Card, CardActions, useTheme } from '@mui/material'
import { useState } from 'react'
import { ScmDataDisplayBaseProps } from '../../../smart-city-monitor/types/base-props'
import ScmCardContent from './scm-card-content'
import ScmCardErrorContent from './scm-card-error-content'
import ScmCardHeader from './scm-card-header'
import ScmCardLoadingContent from './scm-card-loading-content'

function ScmCard({
  custom,
  flipContent,
  children,
  isConnected,
  isError,
}: ScmDataDisplayBaseProps) {
  const theme = useTheme()
  const color = custom?.color ?? theme.palette.primary.main
  const title = custom?.title ?? null
  const height = custom?.height ?? null
  const loadingContentHeight = custom?.loadingContentHeight ?? null
  const additionalCss = custom?.additionalCss ?? {}
  const [showFlipContent, setShowFlipContent] = useState(false)

  return (
    <Card
      variant="outlined"
      sx={{
        ...additionalCss,
        backgroundImage:
          additionalCss?.backgroundImage && !showFlipContent
            ? additionalCss?.backgroundImage
            : 'none',
        borderColor: color,
        height: height ? ['auto', 'auto', height] : 'auto',
        transition: 'background-color 0.5s ease',
        backgroundColor: showFlipContent
          ? color
          : theme.palette.background.default,
      }}
    >
      <ScmCardHeader
        title={title}
        color={color}
        showFlipContent={showFlipContent}
        setShowFlipContent={(value) => setShowFlipContent(value)}
      />

      {!isConnected && !isError && (
        <ScmCardLoadingContent height={loadingContentHeight} color={color} />
      )}

      {isConnected && !isError && (
        <ScmCardContent
          mainContent={children}
          flipContent={flipContent}
          showFlipContent={showFlipContent}
        />
      )}

      {isError && <ScmCardErrorContent height={loadingContentHeight} />}

      <CardActions />
    </Card>
  )
}

export default ScmCard
