import React from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import ScmProvider from './smart-city-monitor/contexts/scm-context'
import { ServerConfig } from './config/server-config'
import AppBar from './components/app-bar/app-bar'
import Footer from './components/footer/footer'
import createTheme from './theme'
import BackgroundGrids from './components/background-grids'
import { SmartCityInformation } from './components/smart-city-information'

const theme = createTheme({
  responsiveFontSizes: false,
  mode: 'light',
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ScmProvider url={ServerConfig.SCMWebSocketURL}>
        <CssBaseline>
          <BackgroundGrids />
          <AppBar />
          <SmartCityInformation />
          <Footer />
        </CssBaseline>
      </ScmProvider>
    </ThemeProvider>
  )
}

export default App
