import { Box } from '@mui/material'
import React from 'react'
import SmartCityGrid from '../assets/smart-city-grid.svg'
import SmartCityGridRight from '../assets/smart-city-grid-right.svg'

function BackgroundGrids() {
  return (
    <>
      <Box
        component="img"
        src={SmartCityGrid}
        alt="Grid"
        sx={{
          position: 'absolute',
          zIndex: -1,
          top: 80,
          width: '15vw',
        }}
      />
      <Box
        component="img"
        src={SmartCityGridRight}
        alt="Grid"
        sx={{
          position: 'absolute',
          zIndex: -1,
          top: 'calc(160px + (320px - 20vw))',
          right: 0,
          width: '13vw',
        }}
      />
    </>
  )
}

export default BackgroundGrids
