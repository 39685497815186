import { createSvgIcon } from '@mui/material'

export const AirCo2 = createSvgIcon(
  <svg viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="49" cy="49" r="48.75" stroke="#8DC63F" strokeWidth="0.5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.8722 41.5804L71.1622 38.8178C71.182 38.6291 71.2091 38.4676 71.2291 38.3567C71.2392 38.301 71.249 38.2501 71.256 38.2137L71.2564 38.2117C71.1712 35.1002 68.6196 32.6072 65.5172 32.6072C64.421 32.6072 63.3838 32.9355 62.4545 33.519L59.0578 35.6518L57.4045 31.9976C55.3999 27.5668 50.989 24.5 45.8793 24.5C38.9003 24.5 33.2153 30.1711 33.2069 37.1988C33.208 37.2105 33.2125 37.2579 33.2278 37.3685C33.23 37.3839 33.2332 37.4066 33.237 37.4339C33.2478 37.5097 33.2635 37.6207 33.275 37.7085C33.2934 37.8495 33.3146 38.0306 33.3286 38.2337L33.5674 41.7046L30.0979 41.9641C24.7279 42.3657 20.5 46.8113 20.5 52.2705C20.5 58.019 25.1506 62.6685 30.8621 62.6685H70.1379C75.8494 62.6685 80.5 58.019 80.5 52.2705C80.5 47.7351 77.5828 43.9066 73.4968 42.4902L70.8722 41.5804ZM29.8368 38.4739C22.6748 39.0095 17 44.9451 17 52.2705C17 59.9434 23.209 66.1685 30.8621 66.1685H70.1379C77.791 66.1685 84 59.9434 84 52.2705C84 46.1757 80.0724 41.0653 74.6431 39.1832C74.6548 39.0722 74.6758 38.9635 74.6968 38.8553C74.7278 38.6954 74.7586 38.5365 74.7586 38.3725C74.7586 33.2621 70.6144 29.1072 65.5172 29.1072C64.6377 29.1072 63.7919 29.2357 62.9912 29.47C62.1357 29.7203 61.3318 30.0912 60.5933 30.5549C60.1083 29.4828 59.5112 28.4719 58.817 27.5379C55.8737 23.5781 51.1837 21 45.8793 21C37.0922 21 29.9292 28.0327 29.712 36.8041C29.7086 36.9404 29.7069 37.0772 29.7069 37.2144C29.7069 37.4689 29.7416 37.7136 29.7762 37.957C29.7985 38.1142 29.8207 38.2708 29.8335 38.4293C29.8347 38.4442 29.8358 38.459 29.8368 38.4739Z"
      fill="#8DC63F"
    />
    <path
      d="M42.585 48.66C42.48 47.52 42.03 46.635 41.235 45.975C40.455 45.33 39.405 45 38.1 45C36.51 45 35.25 45.51 34.35 46.5C33.45 47.505 33 48.9 33 50.685C33 52.455 33.45 53.85 34.335 54.855C35.22 55.875 36.435 56.37 37.965 56.37C39.285 56.37 40.38 55.98 41.25 55.2C42.12 54.42 42.585 53.43 42.615 52.2H40.86C40.8 52.98 40.515 53.595 40.005 54.06C39.495 54.525 38.835 54.75 38.04 54.75C37.05 54.75 36.255 54.39 35.685 53.64C35.115 52.905 34.83 51.9 34.83 50.625C34.83 49.35 35.115 48.36 35.685 47.655C36.255 46.965 37.05 46.605 38.07 46.605C38.85 46.605 39.465 46.785 39.945 47.145C40.425 47.505 40.71 48.015 40.815 48.66H42.585Z"
      fill="#8DC63F"
    />
    <path
      d="M52.5738 46.56C53.6238 46.56 54.4338 46.935 55.0338 47.655C55.6188 48.375 55.9188 49.395 55.9188 50.685C55.9188 51.99 55.6188 53.01 55.0188 53.73C54.4188 54.45 53.6088 54.81 52.5738 54.81C51.5238 54.81 50.6988 54.45 50.1138 53.715C49.5138 52.995 49.2288 51.975 49.2288 50.685C49.2288 49.395 49.5138 48.375 50.1138 47.655C50.6988 46.935 51.5238 46.56 52.5738 46.56ZM52.5738 45C50.9538 45 49.6938 45.51 48.7788 46.515C47.8488 47.535 47.3988 48.915 47.3988 50.685C47.3988 52.455 47.8488 53.85 48.7788 54.855C49.6938 55.875 50.9538 56.37 52.5738 56.37C54.1638 56.37 55.4238 55.875 56.3538 54.855C57.2838 53.835 57.7488 52.455 57.7488 50.685C57.7488 48.93 57.2838 47.55 56.3538 46.53C55.4238 45.51 54.1638 45 52.5738 45Z"
      fill="#8DC63F"
    />
    <path
      d="M64.2982 54.555C64.4932 53.97 65.3482 53.235 66.8632 52.35L67.1482 52.185C68.0032 51.69 68.6182 51.165 69.0082 50.625C69.3982 50.085 69.5932 49.47 69.5932 48.765C69.5932 47.805 69.2632 47.04 68.6332 46.455C68.0032 45.885 67.1632 45.585 66.1282 45.585C64.9882 45.585 64.1032 45.915 63.4732 46.56C62.8432 47.22 62.5282 48.12 62.5282 49.275V49.35H64.1482V49.185C64.1482 48.525 64.3132 48.015 64.6432 47.625C64.9732 47.25 65.4382 47.055 66.0382 47.055C66.5782 47.055 67.0132 47.22 67.3432 47.535C67.6732 47.865 67.8532 48.27 67.8532 48.78C67.8532 49.635 67.1332 50.475 65.7232 51.3C65.4532 51.465 65.2582 51.585 65.1232 51.66C64.1032 52.29 63.3832 52.95 62.9632 53.61C62.5432 54.27 62.3332 55.08 62.3332 56.025V56.07H69.5632V54.555H64.2982Z"
      fill="#8DC63F"
    />
  </svg>,
  'AirCo2',
)
