import { createSvgIcon } from '@mui/material'

export const LightOn = createSvgIcon(
  <svg viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="40" cy="40" r="39" stroke="#F7941D" strokeWidth="0.5" />
    <path
      opacity="0.3"
      d="M32.1924 33.2234L43.9039 21.5385H52.7885L64.5001 33.2234C61.9424 34.9695 55.1308 38.4615 48.3462 38.4615C41.5616 38.4615 34.7501 34.9695 32.1924 33.2234Z"
      fill="#F7941D"
    />
    <path
      d="M27.4032 65.6346H27.6532V65.3846V35.2104C27.6532 26.5118 34.6535 19.4272 43.3876 19.1825C43.6069 19.423 43.8665 19.6266 44.1556 19.783C44.4415 21.0808 45.6077 22.0497 47 22.0497H49.9032C51.2136 22.0497 52.3233 21.1917 52.6879 20.0092C53.88 19.6495 54.75 18.5516 54.75 17.2496C54.75 15.6543 53.4442 14.3654 51.8387 14.3654H45.5484C44.467 14.3654 43.5215 14.9502 43.0195 15.8195C32.5817 16.2527 24.25 24.7663 24.25 35.2104V65.3846V65.6346H24.5H27.4032ZM44.9448 22.2703L44.7806 22.4362L44.9448 22.2703C44.7529 22.0803 44.4428 22.0803 44.2509 22.2703L42.8823 23.625C42.689 23.8163 42.689 24.1277 42.8823 24.319C43.0742 24.509 43.3843 24.509 43.5762 24.319L44.9448 22.9643C45.1381 22.773 45.1381 22.4617 44.9448 22.2703ZM51.815 18.218C51.7657 18.6291 51.5936 19.1534 51.2126 19.6024C50.7569 20.1393 49.9829 20.5918 48.6935 20.5918C47.4042 20.5918 46.6302 20.1393 46.1745 19.6024C45.7935 19.1534 45.6214 18.6291 45.5721 18.218H51.815ZM48.4516 22.9865C48.1823 22.9865 47.9597 23.2033 47.9597 23.476V28.4688C47.9597 28.7415 48.1823 28.9582 48.4516 28.9582C48.7209 28.9582 48.9435 28.7415 48.9435 28.4688V23.476C48.9435 23.2033 48.7209 22.9865 48.4516 22.9865Z"
      fill="#F7941D"
      stroke="#F7941D"
      strokeWidth="0.5"
    />
  </svg>,
  'LightOff',
)
