import { Box } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { Wifi } from '../../../icons/wifi'
import { WifiHalfFilled } from '../../../icons/wifi-half-filled'
import { ScmDataDisplayBaseProps } from '../../../smart-city-monitor/types/base-props'
import { SensorGroupDataWifiHotspots } from '../../../smart-city-monitor/types/sensors'
import LastUpdateInfo from '../last-update-info'
import QuickStatsVertical from '../quick-stats-vertical'
import ScmCard from '../scm-card/scm-card'

import WifiHotspotsFlipContent from './wifi-hotspots-flip-content'

function WifiHotspotsCard({
  data,
  isConnected,
  isError,
}: ScmDataDisplayBaseProps) {
  const wifiHotspotData = data as SensorGroupDataWifiHotspots
  const spotColor = '#92278F'

  return (
    <ScmCard
      data={wifiHotspotData}
      isConnected={isConnected}
      isError={isError}
      custom={{
        title: 'Smart Wifi',
        color: spotColor,
        loadingContentHeight: '251px',
        additionalCss: {
          backgroundImage: 'url("/background-images/wifi-hotspots.svg")',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom',
        },
      }}
      flipContent={<WifiHotspotsFlipContent />}
    >
      <Box
        sx={{
          textAlign: 'center',
          p: 4,
        }}
      >
        <Grid container sx={{ p: 0 }} spacing={2}>
          <Grid xs={12} sm={6} md={6}>
            <QuickStatsVertical
              value={wifiHotspotData?.aggregation.totalCount ?? 0}
              title="Access Points"
              icon={<Wifi sx={{ height: 80, width: 80 }} />}
            />
          </Grid>
          <Grid xs={12} sm={6} md={6}>
            <QuickStatsVertical
              value={wifiHotspotData?.aggregation.associatedClientsCount ?? 0}
              title="Verbundene Geräte"
              icon={<WifiHalfFilled />}
            />
          </Grid>
        </Grid>
      </Box>
      <LastUpdateInfo
        date={wifiHotspotData?.updatedAt}
        absouluteBottonPosition="-10px"
      />
    </ScmCard>
  )
}

export default WifiHotspotsCard
