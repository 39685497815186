import { ReactElement } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import { Box, Divider } from '@mui/material'
import LogoDcc from '../../assets/logo-dcc.png'
import LogoDuisburg from '../../assets/logo-duisburg.png'
import LogoDvv from '../../assets/logo-dvv.png'
import LogoSmartCityDuisburg from '../../assets/logo-smart-city-duisburg.png'

function Partner(): ReactElement {
  return (
    <Box sx={{ maxWidth: 775, marginLeft: 'auto', marginRight: 'auto' }}>
      <Grid container sx={{ mb: 3 }}>
        <Grid xs display="flex" justifyContent="center" alignItems="flex-start">
          <Box
            component="img"
            src={LogoSmartCityDuisburg}
            alt="Logo der Smart City Duisburg"
            sx={{
              maxWidth: '185px',
              width: '15vw',
              borderRight: 'var(--Grid-borderWidth) solid',
              borderColor: '#000',
            }}
          />
        </Grid>
        <Grid
          xs
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          sx={{ width: '1px' }}
        >
          <Divider orientation="vertical" />
        </Grid>

        <Grid xs display="flex" justifyContent="center" alignItems="flex-start">
          <Box
            component="img"
            src={LogoDvv}
            alt="Logo der Duisburger Versorgungs- und Verkehrsgesellschaft mbH"
            sx={{
              maxWidth: '153px',
              width: '15vw',
            }}
          />
        </Grid>
        <Grid
          xs
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          sx={{ width: '1px' }}
        >
          <Divider orientation="vertical" />
        </Grid>
        <Grid xs display="flex" justifyContent="center" alignItems="flex-start">
          <Box
            component="img"
            src={LogoDcc}
            alt="Logo der Duisburg City.Com"
            sx={{
              maxWidth: '185px',
              width: '15vw',
            }}
          />
        </Grid>
        <Grid
          xs
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          sx={{ width: '1px' }}
        >
          <Divider orientation="vertical" />
        </Grid>
        <Grid xs display="flex" justifyContent="center" alignItems="flex-start">
          <Box
            component="img"
            src={LogoDuisburg}
            alt="Logo der Stadt Duisburg"
            sx={{
              maxWidth: '131px',
              width: '15vw',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Partner
