import { Box } from '@mui/material'

const ParkingFlipContent = () => {
  return (
    <Box
      sx={{
        height: ['auto', 'auto', '616px'],
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <p>
        Optische Sensoren scannen den zur Verfügung stehenden Parkraum auf den
        innenstadtnahen Parkflächen.
      </p>

      <p>
        Für Sie noch wichtig: Die gesetzlichen Regeln zum Datenschutz werden
        vollumfänglich erfüllt! Die Technik zeichnet keine Bilder oder
        Filmsequenzen auf. Bilder werden direkt vor Ort in Signale umgesetzt.
        Der Sensor meldet nur, ob ein Parkplatz frei oder besetzt ist.
      </p>
    </Box>
  )
}

export default ParkingFlipContent
