import { Typography } from '@mui/material'
import React from 'react'

function Introduction() {
  return (
    <div>
      <div>
        <Typography variant="h1" color="primary" sx={{ mb: 2 }}>
          Smart City Monitor Duisburg
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Als Grundlage der Smart City-Initiative in Duisburg wird eine
          IoT-Plattform benötigt. Die IoT-Plattform ermöglicht die Verwaltung
          und Steuerung einer großen Anzahl internetfähiger Geräte (Sensoren)
          über WLAN, LoRaWAN, Mobilfunk und Kabelverbindungen. Der Smart City
          Monitor Duisburg dient dabei als Fenster der IoT-Plattform und
          ermöglicht allen Bürgern, aktuelle Kennzahlen einzusehen.
        </Typography>
      </div>
    </div>
  )
}

export default Introduction
