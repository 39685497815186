import React, { ReactElement } from 'react'
import { Box, Button, Link, Toolbar, Typography, useTheme } from '@mui/material'

function BottomLinks(): ReactElement {
  const theme = useTheme()

  const copyright =
    'Copyright © 2022 Duisburger Versorgungs- und Verkehrsgesellschaft mbH'

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Toolbar
        disableGutters
        sx={{
          flexGrow: 1,
          [theme.breakpoints.down('md')]: {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          },
        }}
      >
        <Box
          sx={{
            [theme.breakpoints.up('md')]: { flexGrow: 1 },
          }}
        >
          <Link
            size="small"
            component={Button}
            href="https://www.dvv.de/impressum"
            target="_blank"
            sx={{
              textDecoration: 'none',
            }}
          >
            Impressum
          </Link>
          <Link
            size="small"
            sx={{ ml: 4, flexGrow: 1, textDecoration: 'none' }}
            component={Button}
            href="https://www.dvv.de/datenschutz"
            target="_blank"
          >
            Datenschutz
          </Link>
        </Box>
        <Typography
          variant="body2"
          sx={{
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          }}
        >
          {copyright}
        </Typography>
      </Toolbar>
      <Typography
        variant="caption"
        sx={{
          display: 'none',
          [theme.breakpoints.down('md')]: {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            fontWeight: '12px !important',
            textAlign: 'center',
          },
        }}
      >
        {copyright}
      </Typography>
    </Box>
  )
}

export default BottomLinks
