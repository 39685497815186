import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import useWebSocket from 'react-use-websocket'
import {
  WsEventResponse,
  WsResponse,
  WsResponseSensorGroupDataUpdate,
} from '../types/ws-response'
import { SensorData, SensorDataType, SensorGroupData } from '../types/sensors'
import { WsEvents } from '../types/ws-events'

export enum ConnectionStatus {
  CONNECTED = 'connected',
  CONNECTING = 'connecting',
  ERROR = 'error',
}

export type SmcProviderContextType = {
  getValue: (key: SensorDataType) => SensorGroupData<SensorData> | null
  getConnectionStatus: () => ConnectionStatus
}

const ScmContext = createContext<SmcProviderContextType>({
  getValue: () => {
    throw new Error('Not implemented')
  },
  getConnectionStatus: () => {
    throw new Error('Not implemented')
  },
})

interface ScmProviderProps {
  url: string
  shouldConnect?: boolean
  shouldReconnect?: boolean
  reconnectAttempts?: number
  reconnectInterval?: number
  children: ReactNode
}

function ScmProvider({
  url,
  shouldConnect,
  shouldReconnect = true,
  reconnectInterval,
  reconnectAttempts,
  children,
}: ScmProviderProps): React.ReactElement {
  const [value, setValue] = useState<{
    [key in SensorDataType]?: SensorGroupData<SensorData>
  }>({})

  const [connectionStatus, setConnectionStatus] = useState(
    ConnectionStatus.CONNECTING,
  )

  const { lastJsonMessage } = useWebSocket(
    url,
    {
      shouldReconnect: () => shouldReconnect,
      share: true,
      retryOnError: true,
      reconnectInterval,
      reconnectAttempts,
      onReconnectStop: () => setConnectionStatus(ConnectionStatus.ERROR),
      onError: () => setConnectionStatus(ConnectionStatus.ERROR),
      onOpen: () => setConnectionStatus(ConnectionStatus.CONNECTED),
    },
    shouldConnect,
  )

  useEffect(() => {
    if (!lastJsonMessage) {
      return
    }

    const message = lastJsonMessage as unknown as WsResponse
    if (message.msg !== 'event') {
      return
    }

    const eventMsg = message as WsEventResponse

    if (eventMsg.event.action !== WsEvents.SensorGroupDataUpdate) {
      return
    }

    const updateMsg = eventMsg as WsResponseSensorGroupDataUpdate

    updateMsg.event.payload.forEach((sensorData) => {
      setValue((prevState) => ({
        ...prevState,
        [sensorData.type]: sensorData,
      }))
    })
  }, [lastJsonMessage])

  const getValue = useCallback(
    (key: SensorDataType): SensorGroupData<SensorData> | null => {
      return Object.prototype.hasOwnProperty.call(value, key)
        ? value[key] ?? null
        : null
    },
    [value],
  )

  const getConnectionStatus = useCallback(
    () => connectionStatus,
    [connectionStatus],
  )

  const rvm = useMemo(
    () => ({
      getValue,
      getConnectionStatus,
    }),
    [getValue, getConnectionStatus],
  )

  return <ScmContext.Provider value={rvm}>{children}</ScmContext.Provider>
}

ScmProvider.defaultProps = {
  reconnectInterval: 5000,
  reconnectAttempts: 30,
  shouldConnect: true,
  shouldReconnect: true,
}

export const useScmContext = (): SmcProviderContextType =>
  useContext(ScmContext)

export default ScmProvider
