import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'

const EnvironmentFlipContent = () => {
  return (
    <Box
      sx={{
        height: ['auto', 'auto', '330px'],
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      Umweltsensoren messen Temperatur und Luftqualität anhand
      charakteristischer Parameter. Sie ersetzen nicht die gesetzl.
      vorgeschriebenen Messstationen oder auch die Wetterstationen der
      Wetterdienste, können aber deren Informationen lokal sinnvoll ergänzen.
      <Table
        size="small"
        padding="none"
        sx={{
          mt: 1,
          maxWidth: '540px',
          '& td, & th': {
            color: 'white',
          },
          '& th, & .MuiTableCell-head': {
            fontWeight: 600,
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>ppm</TableCell>
            <TableCell>Luftqualität</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell variant="head">380 - 420</TableCell>
            <TableCell>Sehr saubere Luftqualität</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">420 - 440</TableCell>
            <TableCell>Sehr gute Luftqualität</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">440 - 480 </TableCell>
            <TableCell>Gute Luftqualität</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">480 - 520 </TableCell>
            <TableCell>Befriedigende Luftqualität</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">520 - 620 </TableCell>
            <TableCell>Ausreichende Luftqualität</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">620 - 720 </TableCell>
            <TableCell>Mangelhafte Luftqualität</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">&gt; 720 </TableCell>
            <TableCell>Deutlich belastete Luftqualität</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  )
}

export default EnvironmentFlipContent
