/** @jsxImportSource @emotion/react */
import { Box, Grid } from '@mui/material'
import { ReactElement } from 'react'
import ParkingHalfFilledIcon from '../../../icons/parking-half-filled-icon'
import ParkingIcon from '../../../icons/parking-icon'
import { ScmDataDisplayBaseProps } from '../../../smart-city-monitor/types/base-props'
import { SensorGroupDataParking } from '../../../smart-city-monitor/types/sensors'
import LastUpdateInfo from '../last-update-info'
import QuickStatsHorizontal from '../quick-stats-horizontal'
import ScmCard from '../scm-card/scm-card'

import ParkingChart from './parking-chart'
import ParkingFlipContent from './parking-flip-content'

function ParkingCard({
  data,
  isConnected,
  isError,
}: ScmDataDisplayBaseProps): ReactElement {
  const parkingData = data as SensorGroupDataParking
  const spotColor = '#00AEFF'

  return (
    <ScmCard
      data={parkingData}
      isConnected={isConnected}
      isError={isError}
      custom={{
        title: 'Parkplätze',
        color: spotColor,
        loadingContentHeight: '632px',
        additionalCss: {
          backgroundImage: 'url("/background-images/parking.svg")',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom',
        },
      }}
      flipContent={<ParkingFlipContent />}
    >
      <Box
        sx={{
          p: [4],
        }}
      >
        <Grid container direction="row">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            sx={{
              textAlign: ['center', 'center', 'center', 'left'],
            }}
          >
            <QuickStatsHorizontal
              value={parkingData?.aggregation.totalCount ?? 0}
              title="Digital erfasste Parkplätze"
              icon={<ParkingIcon />}
              width={160}
              color={spotColor}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            sx={{
              textAlign: ['center', 'center', 'center', 'right'],
            }}
          >
            <QuickStatsHorizontal
              value={parkingData?.aggregation.occupiedCount ?? 0}
              title="Besetzte Parkplätze"
              icon={<ParkingHalfFilledIcon />}
              width={160}
              color={spotColor}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              height: ['auto', 'auto', 'auto', '436px'],
              textAlign: 'center',
              pt: [0, 0, 8],
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '250px',
              }}
            >
              <ParkingChart data={data} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <LastUpdateInfo date={data?.updatedAt} absouluteBottonPosition="-10px" />
    </ScmCard>
  )
}

export default ParkingCard
