import { Box, Divider, Typography } from '@mui/material'
import { FC, ReactElement } from 'react'

interface QuickstatsHorizontalProps {
  value: string | number
  title: string | ReactElement
  icon: ReactElement
  width: number
  color: string
}

const dbg = 0

const QuickStatsHorizontal: FC<QuickstatsHorizontalProps> = ({
  value,
  title,
  icon,
  width,
  color,
}) => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: ['center'],
        justifyContent: ['flex-start', 'center'],
        border: dbg ? '2px solid red' : 'none',
        textAlign: 'center',
      }}
    >
      <Box sx={{ width: [80], height: 100 }}>{icon}</Box>
      <Box sx={{ width: 15, ml: 1 }}>
        <Divider color={color} sx={{ height: 1.9 }} />
      </Box>
      <Box sx={{ width, px: 1 }}>
        <Box sx={{ textAlign: 'left' }}>
          <Typography sx={{ mr: 1, whiteSpace: 'nowrap' }} variant="h2">
            {value}
          </Typography>
        </Box>

        <Box sx={{ textAlign: 'left' }}>
          <Typography variant="body2">{title}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default QuickStatsHorizontal
