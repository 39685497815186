import { Box, CardContent, CircularProgress, Fade } from '@mui/material'

interface Props {
  height: string | null
  color: string
}

const ScmCardLoadingContent = (props: Props) => {
  const { height, color } = props
  return (
    <CardContent
      sx={{
        pt: 0,
        height: height ? ['auto', 'auto', height] : 'auto',
        position: 'relative',
      }}
    >
      <Fade in timeout={500}>
        <Box
          sx={{
            textAlign: 'center',
            lineHeight: height ? ['auto', 'auto', height] : 'auto',
          }}
        >
          <CircularProgress sx={{ color }} />
        </Box>
      </Fade>
    </CardContent>
  )
}

export default ScmCardLoadingContent
