import { createSvgIcon } from '@mui/material'

export const Wifi = createSvgIcon(
  <svg viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="40.5" cy="40.5" r="39.75" stroke="#92278F" strokeWidth="0.5" />
    <path
      d="M42.8701 23.5C49.5159 23.6163 56.8738 26.5248 63.045 32.2836C63.6383 32.8653 64.0537 33.5052 63.9944 34.3196C63.935 35.2503 63.401 35.8901 62.5109 36.2392C61.6208 36.5882 60.8495 36.3555 60.0781 35.7156C58.654 34.5522 57.2892 33.3307 55.8057 32.2836C52.0674 29.7823 47.9138 28.3863 43.4041 28.0372C36.2836 27.4555 29.9344 29.317 24.2973 33.6215C23.4666 34.2614 22.6952 35.0176 21.8645 35.7156C21.1524 36.2973 20.381 36.4718 19.4909 36.1228C18.6009 35.832 18.1262 35.1921 18.0075 34.3196C17.9481 33.6215 18.2448 32.9817 18.7789 32.4581C21.4491 29.8405 24.5347 27.7464 28.0356 26.1758C32.1299 24.3725 36.5209 23.5 42.8701 23.5Z"
      fill="#92278F"
    />
    <path
      d="M40.6152 32.4581C47.0831 32.5745 52.3641 34.6104 56.7551 38.8568C57.7045 39.7875 57.7639 41.1254 56.8738 42.0561C55.9837 42.9869 54.5596 42.9869 53.5509 42.0561C50.8807 39.4967 47.7951 37.8679 44.1162 37.2281C38.7758 36.2973 33.91 37.5771 29.6377 40.9509C29.2224 41.2999 28.807 41.7071 28.3916 42.0561C27.4422 42.9287 26.0774 42.9287 25.128 42.0561C24.238 41.1836 24.238 39.7875 25.1874 38.915C28.7476 35.4248 33.0793 33.3307 38.0637 32.6908C39.0131 32.5163 39.9625 32.5163 40.6152 32.4581Z"
      fill="#92278F"
    />
    <path
      d="M40.7932 41.4163C44.4722 41.4744 47.5578 42.6378 50.1686 44.9646C51.4147 46.0699 51.1774 47.4659 50.584 48.2221C49.6939 49.2692 48.2105 49.3274 47.1424 48.3385C46.1337 47.4078 45.0062 46.6515 43.6415 46.3025C40.3779 45.3718 37.4703 46.0699 34.9781 48.3385C34.0287 49.211 32.7826 49.3274 31.8332 48.5711C30.7651 47.6986 30.6465 46.2444 31.5959 45.2555C33.732 43.1614 36.3429 41.8816 39.3691 41.5326C39.9032 41.4744 40.4372 41.4163 40.7932 41.4163Z"
      fill="#92278F"
    />
    <path
      d="M45.0656 54.4463C45.0656 56.6567 43.2261 58.46 40.9713 58.46C38.7164 58.46 36.8769 56.6567 36.8769 54.4463C36.8769 52.2358 38.7164 50.4326 40.9713 50.3744C43.2261 50.3744 45.0656 52.1777 45.0656 54.4463Z"
      fill="#92278F"
    />
  </svg>,
  'Wifi',
)
