import { Box, CardContent, Typography } from '@mui/material'

interface Props {
  height: string | null
}

const ScmCardErrorContent = (props: Props) => {
  const { height } = props
  return (
    <CardContent
      sx={{
        pt: 0,
        height: height ? ['auto', 'auto', height] : 'auto',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        <Typography
          sx={{ lineHeight: height ? ['auto', 'auto', height] : 'auto' }}
        >
          Es konnten keine Daten geladen werden
        </Typography>
      </Box>
    </CardContent>
  )
}

export default ScmCardErrorContent
