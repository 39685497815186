import React, { ReactElement } from 'react'
import { AppBar as MuiAppBar, Box, Button, Link, Toolbar } from '@mui/material'
import Logo from './logo'

function AppBar(): ReactElement {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <MuiAppBar
        elevation={0}
        position="static"
        sx={{ p: '10px 60px 10px 60px' }}
      >
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, height: 57 }}>
            <Logo />
          </Box>
          <Link
            color="inherit"
            component={Button}
            href="https://www.dvv.de/kontakt"
            target="_blank"
            sx={{
              textDecoration: 'none',
            }}
          >
            Kontakt
          </Link>
        </Toolbar>
      </MuiAppBar>
    </Box>
  )
}

export default AppBar
