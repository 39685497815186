import { Box } from '@mui/material'

const WifiHotspotsFlipContent = () => {
  return (
    <Box
      sx={{
        height: ['auto', 'auto', '234.78px'],
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      Öffentliches WLAN für Bürger und Besucher steht rund um die Uhr zur
      Verfügung. Die Nutzung ist mit jedem mobilen Gerät einfach und mit einer
      hohen Verfügbarkeit möglich. Ein kostenloser Service Ihrer Stadtwerke
      Duisburg.
    </Box>
  )
}

export default WifiHotspotsFlipContent
