import { SvgIcon } from '@mui/material'
import React from 'react'
import { ReactComponent } from './wifi-half-filled.svg'

export function WifiHalfFilled() {
  return (
    <SvgIcon
      component={ReactComponent}
      inheritViewBox
      sx={{ width: 80, height: 80 }}
    />
  )
}
