import { createSvgIcon } from '@mui/material'

export const LightOff = createSvgIcon(
  <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="40" cy="40" r="39" stroke="#F7941D" strokeWidth="0.5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27 68H30.9191V35.3888C30.9191 26.2067 38.2915 18.722 47.5059 18.4299C47.7261 18.6616 47.9804 18.8609 48.2607 19.0198C48.6037 20.4381 49.8908 21.4877 51.4216 21.4877H54.5199C55.9553 21.4877 57.1759 20.565 57.6056 19.2812C58.8987 18.8578 59.8358 17.6504 59.8358 16.2216C59.8358 14.4384 58.3766 13 56.5853 13H49.8725C48.6945 13 47.6601 13.6221 47.0899 14.5553C35.9122 15.0598 27 24.1906 27 35.3888V68ZM56.3928 17.3988C56.3209 17.7842 56.1454 18.2415 55.8077 18.6395C55.3515 19.177 54.5672 19.6446 53.2289 19.6446C51.8907 19.6446 51.1064 19.177 50.6502 18.6395C50.3125 18.2415 50.137 17.7842 50.0651 17.3988H56.3928Z"
      fill="#F7941D"
    />
  </svg>,
  'LightOff',
)
