import { ThemeOptions } from '@mui/material'

const neutral = {
  100: '#F3F4F6',
  200: '#E5E7EB',
  300: '#D1D5DB',
  400: '#9CA3AF',
  500: '#6B7280',
  600: '#4B5563',
  700: '#374151',
  800: '#1F2937',
  900: '#111827',
}

const background = {
  default: '#0B0F19',
  paper: neutral[900],
}

const darkThemeOptions: ThemeOptions = {
  palette: {
    background,
  },
}

export default darkThemeOptions
