import { Box, Typography } from '@mui/material'
import { ReactElement } from 'react'
import { Clock } from '../../icons/clock'

interface LastUpdateInfoProps {
  date: string | Date | null
  absouluteBottonPosition: string | undefined
}

function LastUpdateInfo({
  date,
  absouluteBottonPosition,
}: LastUpdateInfoProps): ReactElement {
  let text = 'unbekannt'
  let realDate = date

  if (typeof date === 'string') {
    realDate = new Date(date)
  }

  if (realDate instanceof Date && !Number.isNaN(realDate)) {
    text = `${realDate.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })} | ${realDate.toLocaleDateString()}`
  }

  return (
    <Box
      sx={{
        display: 'inline-flex',
        justifyContent: 'center',
        position: 'absolute',
        bottom: absouluteBottonPosition,
      }}
    >
      <Clock
        sx={{
          // fontSize: theme.typography.pxToRem(15),
          width: 20,
          height: 20,
        }}
      />
      <Box component={Typography} variant="caption" sx={{ pl: 1 }}>
        Zuletzt aktualisiert um {text}
      </Box>
    </Box>
  )
}

export default LastUpdateInfo
