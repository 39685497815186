import {
  createTheme as createMuiTheme,
  responsiveFontSizes,
  Theme,
} from '@mui/material'
import baseThemeOptions from './base-theme-option'
import darkThemeOptions from './dark-theme-option'
import lightThemeOptions from './light-theme-option'

interface ThemeConfig {
  responsiveFontSizes?: boolean
  mode: 'light' | 'dark'
}

const createTheme = (config: ThemeConfig): Theme => {
  let theme = createMuiTheme(
    baseThemeOptions,
    config.mode === 'dark' ? darkThemeOptions : lightThemeOptions,
  )

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  return theme
}

export default createTheme
