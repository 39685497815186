import { ThemeOptions } from '@mui/material'

const background = {
  default: '#FFFFFF',
  paper: '#FFFFFF',
}

const lightThemeOptions: ThemeOptions = {
  palette: {
    background,
    primary: {
      light: '#ffffff',
      main: '#0060A9',
    },
    secondary: {
      light: '#ffffff',
      main: '#ED1C24',
    },
  },
}

export default lightThemeOptions
