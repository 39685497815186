import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import CloseIcon from '@mui/icons-material/Close'
import { Avatar, CardHeader, IconButton } from '@mui/material'

interface ScmCardHeaderProps {
  title: string | null
  color: string
  showFlipContent: boolean
  setShowFlipContent: (showFlipContent: boolean) => void
}

const ScmCardHeader = (props: ScmCardHeaderProps) => {
  const { title, color, showFlipContent, setShowFlipContent } = props

  return (
    <CardHeader
      title={title}
      sx={{
        color: showFlipContent ? 'white' : 'auto',
      }}
      avatar={
        <Avatar
          sx={{
            backgroundColor: color,
            width: 20,
            height: 20,
            zIndex: 10000,
            position: 'absolute',
          }}
        >
          {' '}
        </Avatar>
      }
      action={
        <IconButton
          aria-label="settings"
          onClick={() => setShowFlipContent(!showFlipContent)}
        >
          {showFlipContent ? (
            <CloseIcon fontSize="small" sx={{ color: 'white' }} />
          ) : (
            <QuestionMarkIcon fontSize="small" />
          )}
        </IconButton>
      }
    />
  )
}

export default ScmCardHeader
