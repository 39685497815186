/** @jsxImportSource @emotion/react */
import React, { ReactElement } from 'react'
import { LocationOn } from '@mui/icons-material'
import { css } from '@emotion/react'
import { Box, useTheme } from '@mui/material'
import DigitalTour from './digital-tour'

interface LocationLabelProps {
  label: string
}

function LocationLabel({ label }: LocationLabelProps): ReactElement {
  const labelStyles = css({
    fontWeight: 600,
  })

  return (
    <Box component="span" css={labelStyles}>
      {label}
    </Box>
  )
}

interface LocationInformationProps {
  location: string
}
function LocationInformation({
  location,
}: LocationInformationProps): ReactElement {
  const theme = useTheme()

  const rootStyles = css({
    marginBottom: theme.spacing(2),
  })

  const divStyles = css({
    maxWidth: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  })

  const iconStyles = css({
    marginLeft: -6,
    marginRight: 10,
    fontSize: theme.typography.pxToRem(28),
  })

  return (
    <Box component="div" css={rootStyles}>
      <Box component="div" css={divStyles}>
        <LocationOn css={css(iconStyles)} />
        <LocationLabel label="Friedrich-Wilhelm-Straße" />
      </Box>
      <Box component="div">
        <DigitalTour location={location} />
      </Box>
    </Box>
  )
}

export default LocationInformation
