import { createSvgIcon } from '@mui/material'

export const AirPressure = createSvgIcon(
  <svg viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="49" cy="49" r="48.75" stroke="#8DC63F" strokeWidth="0.5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.3722 37.5804L69.6622 34.8178C69.682 34.6291 69.7091 34.4676 69.7291 34.3567C69.7392 34.301 69.749 34.2501 69.756 34.2137L69.7564 34.2117C69.6712 31.1002 67.1196 28.6072 64.0172 28.6072C62.921 28.6072 61.8838 28.9355 60.9545 29.519L57.5578 31.6518L55.9045 27.9976C53.8999 23.5668 49.489 20.5 44.3793 20.5C37.4003 20.5 31.7153 26.1711 31.7069 33.1988C31.708 33.2105 31.7125 33.2579 31.7278 33.3685C31.73 33.3839 31.7332 33.4066 31.737 33.4339C31.7478 33.5097 31.7635 33.6207 31.775 33.7085C31.7934 33.8495 31.8146 34.0306 31.8286 34.2337L32.0674 37.7046L28.5979 37.9641C23.2279 38.3657 19 42.8113 19 48.2705C19 54.019 23.6506 58.6685 29.3621 58.6685H68.6379C74.3494 58.6685 79 54.019 79 48.2705C79 43.7351 76.0828 39.9066 71.9968 38.4902L69.3722 37.5804ZM28.3368 34.4739C21.1748 35.0095 15.5 40.9451 15.5 48.2705C15.5 55.9434 21.709 62.1685 29.3621 62.1685H68.6379C76.291 62.1685 82.5 55.9434 82.5 48.2705C82.5 42.1757 78.5724 37.0653 73.1431 35.1832C73.1548 35.0722 73.1758 34.9635 73.1968 34.8553C73.2278 34.6954 73.2586 34.5365 73.2586 34.3725C73.2586 29.2621 69.1144 25.1072 64.0172 25.1072C63.1377 25.1072 62.2919 25.2357 61.4912 25.47C60.6357 25.7203 59.8318 26.0912 59.0933 26.5549C58.6083 25.4828 58.0112 24.4719 57.317 23.5379C54.3737 19.5781 49.6837 17 44.3793 17C35.5922 17 28.4292 24.0327 28.212 32.8041C28.2086 32.9404 28.2069 33.0772 28.2069 33.2144C28.2069 33.4689 28.2416 33.7136 28.2762 33.957C28.2985 34.1142 28.3207 34.2708 28.3335 34.4293C28.3347 34.4442 28.3358 34.459 28.3368 34.4739Z"
      fill="#8DC63F"
    />
    <path
      d="M33.2071 66.2929C32.8166 65.9024 32.1834 65.9024 31.7929 66.2929L25.4289 72.6569C25.0384 73.0474 25.0384 73.6805 25.4289 74.0711C25.8195 74.4616 26.4526 74.4616 26.8431 74.0711L32.5 68.4142L38.1569 74.0711C38.5474 74.4616 39.1805 74.4616 39.5711 74.0711C39.9616 73.6805 39.9616 73.0474 39.5711 72.6569L33.2071 66.2929ZM33.5 81L33.5 67L31.5 67L31.5 81L33.5 81Z"
      fill="#8DC63F"
    />
    <path
      d="M50.2071 66.2929C49.8166 65.9024 49.1834 65.9024 48.7929 66.2929L42.4289 72.6569C42.0384 73.0474 42.0384 73.6805 42.4289 74.0711C42.8195 74.4616 43.4526 74.4616 43.8431 74.0711L49.5 68.4142L55.1569 74.0711C55.5474 74.4616 56.1805 74.4616 56.5711 74.0711C56.9616 73.6805 56.9616 73.0474 56.5711 72.6569L50.2071 66.2929ZM50.5 81L50.5 67L48.5 67L48.5 81L50.5 81Z"
      fill="#8DC63F"
    />
    <path
      d="M66.2071 66.2929C65.8166 65.9024 65.1834 65.9024 64.7929 66.2929L58.4289 72.6569C58.0384 73.0474 58.0384 73.6805 58.4289 74.0711C58.8195 74.4616 59.4526 74.4616 59.8431 74.0711L65.5 68.4142L71.1569 74.0711C71.5474 74.4616 72.1805 74.4616 72.5711 74.0711C72.9616 73.6805 72.9616 73.0474 72.5711 72.6569L66.2071 66.2929ZM66.5 81L66.5 67L64.5 67L64.5 81L66.5 81Z"
      fill="#8DC63F"
    />
  </svg>,
  'AirPressure',
)
