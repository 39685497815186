import { SvgIcon } from '@mui/material'
import { ReactComponent as Parking } from './parking-half-filled.svg'

function ParkingHalfFilledIcon() {
  return (
    <SvgIcon
      component={Parking}
      inheritViewBox
      sx={{ fontSize: '6.2rem', width: '80px', height: '80px' }}
    />
  )
}

export default ParkingHalfFilledIcon
