import React from 'react'

const Chart = React.lazy(() => import('react-apexcharts'))

export default Chart

export const radialBarBaseConfig = {
  plotOptions: {
    radialBar: {
      hollow: {
        size: '65%',
      },
      dataLabels: {
        showOn: 'always',
        name: {
          offsetY: 70,
          show: true,
          fontSize: '0.9em',
          fontWeight: 300,
          color: '#000000DE',
        },
        value: {
          color: '#F7941D',
          fontSize: '1.5em',
          offsetY: -5,
          show: true,
        },
      },
      total: {
        show: false,
        label: 'Total',
        color: '#F7941D',
        fontSize: '16px',
      },
    },
  },
  fill: {
    type: 'solid',
    colors: ['#F7941D'],
  },
  stroke: {
    lineCap: 'round',
  },
}
