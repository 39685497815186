import { ConnectionStatus, useScmContext } from '../contexts/scm-context'
import {
  ScmDataContainerBaseProps,
  ScmDataDisplayBaseProps,
} from '../types/base-props'
import { SensorData, SensorGroupData } from '../types/sensors'

function ScmDataContainer(props: ScmDataContainerBaseProps) {
  const { group, ...rest } = props
  const { getValue, getConnectionStatus } = useScmContext()

  const connectionStatus = getConnectionStatus()

  const displayProps: ScmDataDisplayBaseProps = {
    data: getValue(group) as SensorGroupData<SensorData>,
    isConnected: connectionStatus === ConnectionStatus.CONNECTED,
    isError: connectionStatus === ConnectionStatus.ERROR,
  }

  return (
    <rest.component
      data={displayProps.data}
      isConnected={displayProps.isConnected}
      isError={displayProps.isError}
    />
  )
}

export default ScmDataContainer
