/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { ReactElement } from 'react'
import logoImage from '../../assets/logo.png'

function Logo(): ReactElement {
  const styles = {
    image: css({
      width: 125,
      // margin: '10px 60px 10px 36px',
    }),
  }
  // eslint-disable-next-line react/no-unknown-property
  return <img css={styles.image} width={125} src={logoImage} alt="DVV Logo" />
}

export default Logo
